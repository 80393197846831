/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu uses Software that is governed by Licenses."), "\n", React.createElement(_components.p, null, "These Licenses are referenced in this License Notice."), "\n", React.createElement(_components.h2, {
    id: "mozilla-public-license-version-20",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mozilla-public-license-version-20",
    "aria-label": "mozilla public license version 20 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mozilla Public License Version 2.0"), "\n", React.createElement(_components.p, null, "Signatu uses a Source Code Form from Mozilla."), "\n", React.createElement(_components.p, null, "This Source Code Form is subject to the terms of the Mozilla Public License (MPL), v. 2.0."), "\n", React.createElement(_components.p, null, "A copy of the MPL can be obtained here: https://www.mozilla.org/en-US/MPL/2.0/."), "\n", React.createElement(_components.p, null, "This Source Code Form is “Incompatible With Secondary Licenses”, as defined by the MPL, v. 2.0"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
